.side-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  box-shadow: 1px 0 3px rgba(211, 211, 211, 0.1);
  transition: width 0.3s ease;
  /* Smooth transition for width change */
}

.side-menu.expanded {
  width: 250px;
  /* Width when expanded */
}

.side-menu.collapsed {
  width: 50px;
  /* Minimal width when collapsed */
}

.side-menu.expanded .expandArea {
  margin-top: 30px;
  width: 125%;
  display: flex;
  justify-content: flex-end;
  z-index: 150;
}

.side-menu.collapsed .expandArea {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
}

.toggle-button {
  cursor: pointer;
  width: 30px;
  height: 30px;
  background-color: #1B1B1E;
  border: solid 0.1em #ffffffb4;
  border-radius: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconMenuStyle {
  background-color: '#7B3AED';
  font-size: '20px';
  padding: '10px';
  border-radius: '5px'; 
}

.menuItems {
  margin-top: 50px;
  width: 100%;
}


.side-menu.expanded .menuItems a {
  text-decoration: none;
  color: #fff;
  display: block;
  /* Change to block for better layout */
  padding: 10px 0px 10px 10px;
  align-items: start;
  font-weight: 600;
  margin-bottom: 10px;
}

.side-menu.collapsed .menuItems a {
  text-decoration: none;
  color: #fff;
  padding: 10px 0px 10px 0px;
  margin-bottom: 10px;
}

.menuItems a:hover {
  background-color: #7B3AED;
  border-radius: 5px;
}

.planCard {
  position: fixed;
  bottom: 50px;
}

.menuItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.side-menu.expanded .menuItemMain {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 5px 0px 5px;
  height: 30px;
}

.side-menu.collapsed .menuItemMain {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 5px 0px 5px;
  height: 30px;
  margin-bottom: 20px;
}

.logoM {
  width: 100%;
  height: 100%;
}
