.statusLabel {}

.currentPlanText {
    display: flex;
    flex-direction: column;
}

.currentPlanText span {
    font-size: 0.7em;
}

.currentPlanText p {
    margin-block: 0px;
    font-size: 1.2em;
}

.status-free {
    background-image: linear-gradient(to right top, #dac4ff, #ccadff, #be96ff, #af7fff, #9f67ff, #975bfd, #8e4efc, #8541fa, #823ff7, #803df3, #7d3cf0, #7b3aed);
    height: 60px;
    width: 180px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 10px 0px 10px;
    border-radius: 10px;
    gap: 20px;
}

.status-basic {
    background-image: linear-gradient(to right top, #dac4ff, #ccadff, #be96ff, #af7fff, #9f67ff, #975bfd, #8e4efc, #8541fa, #823ff7, #803df3, #7d3cf0, #7b3aed);
    height: 60px;
    width: 180px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 10px 0px 10px;
    border-radius: 10px;
    gap: 20px;
}

.status-premium {
    background-image: linear-gradient(to right top, #dac4ff, #ccadff, #be96ff, #af7fff, #9f67ff, #975bfd, #8e4efc, #8541fa, #823ff7, #803df3, #7d3cf0, #7b3aed);
    height: 60px;
    width: 180px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 10px 0px 10px;
    border-radius: 10px;
    gap: 20px;
}

.status-enterprise {
    background-image: linear-gradient(to right top, #dac4ff, #ccadff, #be96ff, #af7fff, #9f67ff, #975bfd, #8e4efc, #8541fa, #823ff7, #803df3, #7d3cf0, #7b3aed);
    height: 60px;
    width: 180px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 10px 0px 10px;
    border-radius: 10px;
    gap: 20px;
}

@media only screen and (max-width: 767px) {
    .status-free {
        width: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px 10px 0px 10px;
        border-radius: 10px;
        gap: 0px;
        height: 30px;
    }

    .status-free span {
        display: none;
    }

    .status-basic {
        width: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px 10px 0px 10px;
        border-radius: 10px;
        gap: 0px;
        height: 30px;
    }

    .status-basic span {
        display: none;
    }

    .status-premium {
        width: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px 10px 0px 10px;
        border-radius: 10px;
        gap: 0px;
        height: 30px;
        font-size: 7px;
    }

    .status-premium span {
        display: none;
    }

    .status-enterprise {
        width: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px 10px 0px 10px;
        border-radius: 10px;
        gap: 0px;
        height: 30px;
        font-size: 6px;
    }

    .status-enterprise span {
        display: none;
    }

    .statusLabel .status-free>svg,
    .statusLabel .status-basic>svg,
    .statusLabel .status-premium>svg,
    .statusLabel .status-enterprise>svg {
        display: none;
    }
}