.AuthPage {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    background-image: linear-gradient(to right top, #110b1d, #120b21, #130b25, #140a28, #140a2c, #140a2c, #140a2c, #140a2c, #140a28, #130b25, #120b21, #110b1d);
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
}

.authArea {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.signToggler {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: solid 1px #ffffff;
    border-radius: 15px;
}

.signToggleButtonLeft {
    text-align: center;
    width: 100%;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    /* Add transition for background-color */
    border-radius: 15px 0px 0px 15px;
}

.signToggleButtonLeft.active {
    background-color: #7B3AED;
    border-radius: 15px 0px 0px 15px;
}

.signToggleButtonRight {
    text-align: center;
    width: 100%;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    /* Add transition for background-color */
    border-radius: 0px 15px 15px 0px;
}

.signToggleButtonRight.active {
    background-color: #7B3AED;
    border-radius: 0px 15px 15px 0px;
}

.AuthLeftSide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 45%;
    padding-right: 50px;
    box-shadow: 2px 0 0 0 rgba(255, 255, 255, 0.034);
    height: 100%;
}

.AuthRightSide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 45%;
    height: 100%;
    margin-left: 5px;
    background-image: linear-gradient(to right top, #110b1d, #120b21, #130b25, #140a28, #140a2c, #140a2c, #140a2c, #140a2c, #140a28, #130b25, #120b21, #110b1d);
}

.signUpWith {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    margin-bottom: 10px;
}

.lineSign {
    width: 80px;
    height: 5px;
    background-color: white;
}

.formAuth {
    display: flex;
    flex-direction: column;
}

.formAuth input {
    height: 48px;
    border-radius: 10px;
    border: none;
    padding-left: 10px;
    margin-bottom: 15px;
}

.signInLine {
    margin-top: 10px;
}

.SignInButton {
    cursor: pointer;
}

.enterButton {
    width: 80px;
    height: 80px;
    border-radius: 30px;
    border: none;
    background-color: #7B3AED;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.enterButton:hover {
    background-color: #7c3aedc0;
    cursor: pointer;
}

.socialButtons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.socialButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 48px;
    border: solid 1px #ffffff;
    border-radius: 6px;
    width: 100%;
    gap: 10px;
}

.socialButton:hover {
    background-color: #ffffff0c;
    cursor: pointer;
}

.logoIcon {
    width: 25px;
}

.errorMessage {
    color: red;
    font-size: 16px;
    margin-top: 10px;
    /* Add margin or padding as needed */
}

.backButton {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 90%;
    margin: 20px 0px 20px 0px;
    cursor: pointer;
}

/* Mobile devices (width < 768px) */
@media only screen and (max-width: 1210px) {
    .AuthPage {
        height: auto;
        overflow-x: hidden;
        
    }

    .authArea {
        flex-direction: column;
    }

    .AuthLeftSide {
        width: 90%;
        padding-right: 0px;
        box-shadow: none;
    }

    .AuthRightSide {
        width: 100%;
        margin-top: 20px;
        background-image: none;
        margin-bottom: 20px;
    }

    .authImg {
        width: 70%;
    }
}   