.workBoardArea {
  width: 100%;
}

.HistoryContent {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  padding: 20px;
  overflow-y: auto;
  height: 80vh;
}


/* Styling the scrollbar track (the part the scrollbar sits in) */
.HistoryContent::-webkit-scrollbar-track {
  background-color: #1B1B1E;
  border-radius: 10px;
}

/* Styling the scrollbar handle */
.HistoryContent::-webkit-scrollbar-thumb {
  background-color: #555;
  border-radius: 10px;
  border: 3px solid #555;
  /* Creates padding around the scroll thumb */
}

/* Changing the scrollbar handle color on hover */
.HistoryContent::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  border: 3px solid #555;
  /* Creates padding around the scroll thumb */

}

/* Styling the scrollbar width */
.HistoryContent::-webkit-scrollbar {
  width: 10px;
  /* Width of the entire scrollbar */
}

.historyTitle {
  text-align: center;
}

.historyItem {
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: auto; /* Adjust the width as needed */
  cursor: pointer;
  height: 300px;
  width: 200px;
}

.productCard {
  text-align: center;
}

.productCard h2 {
  margin: 10px 0;
  font-size: 1.1em;
}

.productCard p {
  margin: 5px 0;
  font-size: 0.9em;
  color: #666;
}

.cardControl {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 90%;
  justify-content: center;
  align-items: center;
}

.editIco {
  color: #FFF;
  cursor: pointer;
}

.editIco:hover {
  color: #7B3AED;
}

.saveButtonTitle {
  background-color: #7B3AED;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 15px 0px;
  cursor: pointer;
  width: 50%;
  margin-top: 10px;
}

.saveButtonTitle:hover {
  background-color: #7c3aedbd;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content {
    width: 40%;
    height: 50vh;
    padding: 20px;
    border-radius: 5px;
    background-color: #1B1B1E;
    overflow-y: scroll;
    padding: 20px; /* Add some padding */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for better visibility */
}

.modal-content pre {
    white-space: pre-wrap; /* Wraps the text */
    word-break: break-word; /* Breaks long words */
}

.productCard {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 600;
    background-color: #1B1B1E;
    border-radius: 15px;
    margin-bottom: 10px;
}

/* Styling the scrollbar track (the part the scrollbar sits in) */
.modal-content::-webkit-scrollbar-track {
    background-color: #1B1B1E;
    border-radius: 10px;
  }
  
  /* Styling the scrollbar handle */
  .modal-content::-webkit-scrollbar-thumb {
    background-color: #555;
    border-radius: 10px;
    border: 3px solid #555;
    /* Creates padding around the scroll thumb */
  }
  
  /* Changing the scrollbar handle color on hover */
  .modal-content::-webkit-scrollbar-thumb:hover {
    background-color: #555;
    border: 3px solid #555;
    /* Creates padding around the scroll thumb */
  
  }
  
  /* Styling the scrollbar width */
  .modal-content::-webkit-scrollbar {
    width: 10px;
    /* Width of the entire scrollbar */
  }

  .buttonHistoryArea {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  .buttonHistoryModal {
    background-color: #7B3AED;
  color: white;
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  }

  .buttonHistoryModal:active {
    background-color: #7c3aedbd;
  color: white;
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  }

  .styleEditArea {
    width: 100%;
    height: 90%;
    margin: 10px 0px 0px 0px;
    border-radius: 5px;
    resize: none;
    background-color: rgb(63, 63, 63);
    color: #fff;
  }

  /* Styling the scrollbar track (the part the scrollbar sits in) */
.styleEditArea::-webkit-scrollbar-track {
  background-color: #1B1B1E;
  border-radius: 10px;
}

/* Styling the scrollbar handle */
.styleEditArea::-webkit-scrollbar-thumb {
  background-color: #555;
  border-radius: 10px;
  border: 3px solid #555;
  /* Creates padding around the scroll thumb */
}

/* Changing the scrollbar handle color on hover */
.styleEditArea::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  border: 3px solid #555;
  /* Creates padding around the scroll thumb */

}

/* Styling the scrollbar width */
.styleEditArea::-webkit-scrollbar {
  width: 10px;
  /* Width of the entire scrollbar */
}