.home {
  position: absolute;
  width: 100%;
  display: flex;
  background-image: linear-gradient(to right top, #110b1d, #120b21, #130b25, #140a28, #140a2c, #140a2c, #140a2c, #140a2c, #140a28, #130b25, #120b21, #110b1d);
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
}

.homeArea {
  width: 80%;
}

.LogoMainPage {
  width: 12em;
  height: 100%;
}

.MLogoMainPage {
  display: none;
  width: 100%;
  height: 100%;
}

.video-popup {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 5000;
  background-color: #00000059;
  height: 100%;
  
}

.video-popup-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

.close-popup {
  font-size: 25px;
}

header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin-top: 20px;
}

.header-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 50px;
  font-weight: 600;
}

.header-right {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 15px;
  font-weight: 600;
}

.body .block {
  padding: 20px;
}


.first-block .text-section,
.first-block .image-section {
  float: left;
  width: 50%;
}

.text-section h1 {
  font-size: 2.5em;
}

.text-section h2 {
  font-size: 2em;
}

.second-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.third-block {
  display: flex;
  justify-content: center;
}

.third-block .faq-container {
  width: 80%;
}

.block {
  display: flex;
  align-items: center;
}

footer {
  text-align: center;
  width: 90%;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  margin-top: 50px;
}

.footerArea {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.footerBlock {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 20px;
}

.footerBlock a {
  text-decoration: none;
  color: #fff;
}

.mainPictureStyle {
  width: 100%;
}

.swiftProduct {
  width: 50%;
  text-align: center;
  margin: 40px 0px 40px 0px;
}

.modalsubscribe {
  display: none;
}

.modalsubscribeopen {
  display: block;
  position: absolute;
  z-index: 100;
  background-color: #141414;
  padding: 30px;
  margin: 0 auto;
}

.subscribeBlock {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  background-color: #241b3aad;
  box-shadow: 0px 0 7px rgba(211, 211, 211, 0.288);
  padding: 50px 100px 50px 100px;
  align-items: center;
}

.subscribeText {
  width: 45%;
}

.subscribeArea {
  width: 50%;
  
}

.subscribeArea form {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.subscribeArea input {
  width: 50%;
  height: 60px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  padding-left: 10px;
}

.subscribeButton {
  background-color: #7B3AED;
  color: white;
  width: 50%;
  height: 60px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-size: 18px;
}

.subInp {
  width: 100%;
  height: 60px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-size: 18px;
  padding: 0px 5px 0px 5px;
}

.cardBoard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  margin-bottom: 30px;
}

.mainCard {
  width: 50%;
  background-color: #241b3aad;
  padding: 20px;
  height: 180px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  box-shadow: 0px 0 7px rgba(211, 211, 211, 0.288);
  gap: 20px;
}

.mainCardOne {
  width: 30%;
  padding: 20px;
  height: 250px;
  display: flex;
  flex-direction: column;
}

.mainCard h1 {
  margin-block: 0px;
}

.mainCard h2 {
  margin-block: 0px;
}

.mainCard p {
  font-size: 18px;
}

.buttonsMain {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  margin-top: 50px;
}

.share-buttons {
  position: absolute;
  z-index: 10000;
  top: 10%;
  right: 20px;
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.share-button {
  padding: 10px;
  border-radius: 20px;
  background-color: #7B3AED;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.share-button:hover {
  background-color: #7B3AED;
}

.social-share-buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.social-share-buttons button {
  display: flex;
}

.submitButtonMain {
  background-color: #7B3AED;
  color: white;
  width: 30%;
  height: 40px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
}

.loginButton {
  background-color: #7B3AED;
  color: white;
  padding: 8px 20px 8px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.howItWork {
  font-size: 1.1em;
  font-weight: 600;
  text-decoration: none;
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.divide {
  background-color: #fff;
  height: 1px;
  width: 100%;
}

.NotFoundPage {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  background-image: linear-gradient(to right top, #110b1d, #120b21, #130b25, #140a28, #140a2c, #140a2c, #140a2c, #140a2c, #140a28, #130b25, #120b21, #110b1d);
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
}

.notFoundArea {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.label404 {
  background-color: #7B3AED;
  padding: 6px;
  border-radius: 5px;
}

.NotFoundPage header {
  width: 90%;
}

.imgInCard {
  width: 59px;
  height: 56px;
}


/* Tablet devices (width >= 768px and < 1024px) */
@media only screen and (min-width: 1023px) and (max-width: 1500px) {
  .mainCard p {
    font-size: 13px;
  }
}

/* Tablet devices (width >= 768px and < 1024px) */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .mainCard p {
    font-size: 10px;
  }
}

/* Mobile devices (width < 768px) */
@media only screen and (max-width: 767px) {
  .home {
    width: 100%;
  }

  .homeArea {
    width: 90%;
  }

  .block {
    flex-direction: column;
  }

  .first-block .text-section,
  .first-block .image-section {
    width: 100%;
  }

  .third-block .faq-container {
    width: 100%;
  }

  .cardBoard {
    flex-direction: column;
  }

  .mainCard {
    width: 100%;
    height: auto;
  }

  .mainCard p {
    font-size: 12px;
  }

  .subscribeBlock {
    padding: 20px 20px 20px 20px;
  }

  .subscribeText h1 {
    font-size: 13px;
  }

  .subscribeArea {
    width: 40%;
  }

  .subscribeButton {
    font-size: 13px;
  }

  .footerArea {
    width: 100%;
    gap: 20px;
  }

  .LogoMainPage {
    display: none;
    width: 100%;
    height: 100%;
  }

  .MLogoMainPage {
    width: 50px;
    height: 100%;
    display: block;
  }

  header {
    gap: 20px;
  }

  .header-center {
    gap: 20px;
  }

  .header-center a {
    font-size: 12px;
  }

  .loginButton {
    padding: 5px 5px 5px 5px;
  }

  .faq-title-main {
    font-size: 13px;
  }

  .faq-content-style-main {
    font-size: 13px;
  }

  .video-popup-content iframe {
    width: 300px;
    height: 200px;
  }
  
  .subscribeArea form {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  
.subscribeArea input {
  width: 100%;
  font-size: 12px;
  height: 30px;
  padding: 0px;
}

.subscribeButton {
  width: 100%;
  height: 30px;
  font-size: 12px;
}
}