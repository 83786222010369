.ContactUs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ContactUs form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    width: 100%;
}

.ContactUs form input {
    width: 100%;
    height: 48px;
    font-size: 20px;
    border-radius: 5px;
    border: none;
}

.ContactUs form textarea {
    width: 100%;
    height: 48px;
    font-size: 20px;
    height: 250px;
    border-radius: 5px;
    border: none;
}

.submitEmail {
        background-color: #7B3AED;
        color: white;
        padding: 10px 20px;
        border: none;
        cursor: pointer;
        width: 100%;
        margin-top: 5px;
        border-radius: 5px;
}