.App {
  display: flex;
  flex-direction: row;
  height: 100vh;
  /* full height of the viewport */
  background-color: #101012;
  color: #fff;
}

.logoArea {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.logo {
  width: 13em;
}

.mainPageArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.side-menu {
  width: 15%;
  /* your existing styles for side-menu */
  background-color: #101012;
}

.image-to-text {
  width: 85%;
  /* additional styles for image-to-text component */
}

.imageToDocArea {
  width: 90%;
  overflow-y: scroll;
  margin-top: 10px;
}


/* Styling the scrollbar track (the part the scrollbar sits in) */
.imageToDocArea::-webkit-scrollbar-track {
  background-color: #1B1B1E;
  border-radius: 10px;
}

/* Styling the scrollbar handle */
.imageToDocArea::-webkit-scrollbar-thumb {
  background-color: #1B1B1E;
  border-radius: 10px;
  border: 3px solid #1B1B1E;
  /* Creates padding around the scroll thumb */
}

/* Changing the scrollbar handle color on hover */
.imageToDocArea::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  border: 3px solid #555;
  /* Creates padding around the scroll thumb */

}

/* Styling the scrollbar width */
.imageToDocArea::-webkit-scrollbar {
  width: 10px;
  /* Width of the entire scrollbar */
}



.imageLeftRightArea {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.leftImageDoc {
  width: 35%;
  margin-bottom: 20px;
}

.rightImageDoc {
  width: 60%;
  height: 85vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.upload-area {
  border: 2px dashed #ccc;
  padding: 20px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.addImageButton {
  background-color: #7B3AED;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-top: 5px;
  border-radius: 5px;
}

.addImageButton:active {
  background-color: #981cfea9;
}

.upload-btn {
  background-color: #7B3AED;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  width: 50%;
}

.upload-btn:hover {
  background-color: #981cfed5;
}

.upload-area input[type="file"] {
  display: none;
}

.image-preview {
  text-align: center;

}

.image-preview img {
  max-width: 100%;
  margin-top: 10px;
  height: 200px;
}

.submitArea {
  margin-top: 20px;
  width: 100%;
}

.submitButton {
  background-color: #7B3AED;
  color: white;
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
}

.additionalTextArea {
  background-color: #1B1B1E;
  margin: 0px 10px 20px 20px;
  padding: 10px 10px 10px 20px;
  border-radius: 10px;
}

.toggleButton {
  background-color: #7B3AED;
  /* Main color */
  color: white;
  /* Text color */
  border: none;
  /* Remove default border */
  padding: 10px 15px;
  /* Padding for button size */
  border-radius: 5px;
  /* Rounded corners */
  cursor: pointer;
  /* Cursor changes to pointer on hover */
  font-size: 12px;
  /* Font size */
  transition: background-color 0.3s ease;
  /* Smooth transition for hover effect */
}

.toggleButton:hover {
  background-color: #8721e5;
  /* Darker shade for hover effect */
}

.toggleButton:focus {
  outline: none;
  /* Remove focus outline */
}

.toggleButton.active {
  background-color: #7B3AED;
  /* Active state color */
}

.toggleButton.inactive {
  background-color: #1B1B1E;
  /* Inactive state color */
}

.optionsArea {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 5px;
}

.submitButton:disabled {
  background-color: #1B1B1E;
}

.empty-message {
  background-color: #1B1B1E;
  margin: 0px 20px 20px 20px;
  padding: 10px 20px 10px 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
}

.topRightImage {
  margin: 0px 20px 20px 20px;
  padding: 10px 20px 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 30px;
}

.editable-area {
  width: 100%;
  height: 300px;
  background-color: #1B1B1E;
  border-radius: 5px;
  color: #fff;
  resize: none;
  outline: none;
}

/* Styling the scrollbar track (the part the scrollbar sits in) */
.editable-area::-webkit-scrollbar-track {
  background-color: #1B1B1E;
  border-radius: 10px;
}

/* Styling the scrollbar handle */
.editable-area::-webkit-scrollbar-thumb {
  background-color: #555;
  border-radius: 10px;
  border: 3px solid #555;
  /* Creates padding around the scroll thumb */
}

/* Changing the scrollbar handle color on hover */
.editable-area::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  border: 3px solid #555;
  /* Creates padding around the scroll thumb */

}

/* Styling the scrollbar width */
.editable-area::-webkit-scrollbar {
  width: 10px;
  /* Width of the entire scrollbar */
}

.fieldResponseText {
  background-color: #10101298;
  padding: 10px;
  border-radius: 5px;
}

.responseArea {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.responseButtonArea {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  width: 100%;
}

.inResponseButton {
  position: relative;
  float: right;
  right: 10px;
  margin-bottom: 30px;

}

.inResponseButton img {
  width: 40px;
  height: 40px;
}

.textAreaTextToDoc {
  width: 100%;
  height: 200px;
  background-color: #1B1B1E;
  border-radius: 10px;
  resize: none;
  color: #fff;
  padding: 5px 0px 0px 5px;
  outline: none;
}

.faq-container {
  margin: 20px;
  padding: 10px;

}

.faq-main-title {
  text-align: center;
}

.faq-title {
  cursor: pointer;
  padding: 10px;
  border-bottom: 1px solid #1B1B1E;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.faq-title:hover {
  background-color: #1B1B1E
}

.faq-content {
  border-bottom: 1px solid #1B1B1E;
  transition: max-height 0.3s ease-in-out; /* Smooth transition for max-height */
  max-height: 0; /* Start with max-height 0 */
  overflow: hidden; /* Hide content when collapsed */
}

.faq-content-style {
  background-color: #1B1B1E;
  padding: 10px;
  border-radius: 10px;
}

.faq-container-main {
  margin: 20px;
  padding: 10px;
}

.faq-main-title-area {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.faq-main-title-main {
  text-align: center;
}

.faq-title-main {
  cursor: pointer;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.faq-title-main:hover {
  background-color: #190e3042;
  border-radius: 5px;
}

.faq-content-main {
  transition: max-height 0.3s ease-in-out; /* Smooth transition for max-height */
  max-height: 0; /* Start with max-height 0 */
  overflow: hidden; /* Hide content when collapsed */
  
}

.faq-content-style-main {
  background-color: #190e3042;
  padding: 10px;
  border-radius: 10px;
  font-size: 18px;
}

.faq-item-main {
  
}

.about-product-textarea {
  width: 100%;
  height: 150px;
  resize: none;
  border-radius: 5px;
  margin-top: 10px;
  background-color: #1B1B1E;
  outline: none;
  color: #fff;
  padding: 5px 0px 0px 5px;
  
}

.titleFields {
  margin-block-end: 0px;
}

.titleFieldsFirst {
  margin-block-end: 0px;
  margin-block-start: 0px;
}

.optionalLabel {
  font-size: 0.7em;
  font-weight: 400;

}

.descFields {
  margin-block-start: 0px;
  color: #c0c0c0;
}

.spinner-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.switchWorkSpace {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  gap: 50px;
  margin-right: 25px;
  margin-bottom: 20px;
  transition: background-color 0.3s ease, color 0.3s ease, padding 0.3s ease;
  cursor: pointer;
}

/* Tablet devices (width >= 768px and < 1024px) */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .App {
    font-size: 10px;
  }

  .upload-btn {
    width: auto;
  }

  .optionsArea {
    flex-direction: column;
  }
  
  .side-menu {
    width: 20%; /* Adjusted width for tablet */
  }

  .image-to-text {
    width: 80%; /* Adjusted width for tablet */
  }

  .pricing-container {
    flex-direction: column;
    align-items: center;
  }

  .card {
    width: 60%;
  }

  /* Other tablet-specific styles */
}

/* Mobile devices (width < 768px) */
@media only screen and (max-width: 767px) {
  .App {
    font-size: 10px;
  }

  .mainPageArea {
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .upload-btn {
    width: auto;
  }

  .optionsArea {
    flex-direction: column;
  }

  .imageLeftRightArea {
    flex-direction: column;
  }

  .leftImageDoc {
    width: 90%;
  }

  .rightImageDoc {
    width: 90%;
  }
  
  .side-menu {
    width: 20%; /* Adjusted width for tablet */
  }

  .image-to-text {
    width: 80%; /* Adjusted width for tablet */
  }

  .pricing-container {
    flex-direction: column;
    align-items: center;
  }

  .card {
    width: 70%;
  }

  .faq-container {
    padding: 0px;
    margin: 0px;
  }

  .modal-content {
    width: 70%;
  }
  /* Other tablet-specific styles */
}