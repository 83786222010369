.top-page {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 85%;
    height: 70px;
    box-shadow: 0 2px 1px 0 rgba(99, 99, 99, 0.2); /* Horizontal offset, vertical offset, blur radius, spread radius, color */
    padding: 0px 40px 0px 40px
}

.rightSideTop {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 40px;
}

.profile-picture {
    width: 40px;
    height: 40px;
    border-radius: 20px;
}
.profile-picture {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    cursor: pointer; /* Optional: changes the cursor on hover */
}

/* Initial hidden state of the dropdown menu */
.dropdown-menu {
    display: block; /* Always block but hidden by opacity */
    opacity: 0;
    transform: translateY(-10px);
    visibility: hidden; /* Hide it initially */
    position: absolute;
    right: 110px;
    top: 70px;
    background-color: #1B1B1E;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 20px;
    flex-direction: column;
    transition: opacity 0.5s ease, transform 0.5s ease, visibility 0.5s;
}

/* Remove hover styles as it's now click-based */
.profile-picture:hover + .dropdown-menu, 
.dropdown-menu:hover {
    /* Empty or Remove */
}

/* When menu is open */
.menu-open {
    opacity: 1;
    transform: translateY(0);
    visibility: visible; /* Make it visible */
}


.dropmenu-area {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.dropdown-menu button {
    margin: 5px 0;
    width: 100%;
}

.menuButtons {
    background-color: #101012;
    color: #fff;
    border: none;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
}

.menuButtons:hover {
    background-color: #121214;
}

.searchBar {
    background-color: #1B1B1E;
    height: 35px;
    border: none;
    outline: none;
    color: #fff;
    margin-left: 10px;
    padding-left: 10px;
    box-shadow: -1px 0px 0px rgba(231, 231, 231, 0.1);
}

.searchArea {
    display: flex;
    align-items: center;
    background-color: #1B1B1E;
    height: 40px;
    padding: 0px 10px 0px 10px;
    border-radius: 5px;
}