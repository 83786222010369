.PricingPlansArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.PricingPlansArea h1 {
    margin-block: 0px;
}

.pricing-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    padding: 20px;
  }

  
  .card {
    position: relative; /* Add this to position the label */
    border-radius: 10px;
    text-align: center;
    width: 20%; /* Adjust width as needed */
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0px 0px 5px rgba(211, 211, 211, 0.521);
  }
  
  .recommended {
    background-image: linear-gradient(to left bottom, #190a32, #1b0c36, #1d0d3b, #1f0d3f, #210e44, #281252, #301561, #381970, #48218e, #5829ac, #6931cc, #7b3aed);    
    border: none;
    box-shadow: 0px 0px 0px rgba(211, 211, 211, 0.521);
}
  
  .label {
    background: #7B3AED;
    color: white;
    width: 100%;
    font-size: 0.9em;
    border-radius: 8px 8px 0px 0px;
  }
  
  .features ul {
    list-style: none;
    padding: 0;
  }
  
  .features li {
    text-align: left;
    padding: 5px 0;
  }
  
  .pricing-container button {
    background-color: #1e1d1b;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 15px 0px;
    cursor: pointer;
    width: 100%;
  }
  
  .pricing-container button:hover {
    background-color: #7B3AED;
  }
  
  .priceCardHead {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0px 10px 0px;
    padding-bottom: 10px;
  }

  .priceCardTitle {
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  .priceCardTitle h2 {
    margin-block: 0px;
  }

  .priceCardHead h1 {
    margin-block: 0px;
  }

  .divine {
    height: 1px;
    background-color: #ffffff44;
  }

  .priceTag {
    background-color: #1B1B1E;
    padding: 10px;
    border-radius: 5px;
  }

  .priceTagRec {
    background-color: #7c3aed2c;
    padding: 10px;
    border-radius: 5px;
  }

  .planOptions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
  }